define("discourse/plugins/discourse-sift/discourse/initializers/customize-components", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-sift/admin/models/sift-mod", "discourse/plugins/discourse-sift/discourse/components/modal/report-reason"], function (_exports, _pluginApi, _siftMod, _reportReason) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "customize-components",
    after: "inject-objects",
    initialize(container) {
      const modal = container.lookup("service:modal");
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.modifyClass("component:reviewable-item", {
          pluginId: "sift",
          clientSiftDisagree(reviewable, performAction) {
            // Popup disagree reason
            let post_id = reviewable.post_id;
            let moderator_id = api.getCurrentUser().id;
            let callback = (reason, other_reason) => {
              // console.log("In showReportReason callback: reason=" + reason + ", other_reason=" + other_reason);
              // console.log("In showReportReason callback: post_id=" + post_id);
              // console.log("In showReportReason callback: moderator_id=" + moderator_id);

              // Call the perform action to complete the mod action, and on the promise completion call Sift
              performAction().then(function () {
                // console.log("in perform action then()");

                // Call the Sift Reporting endpoint
                _siftMod.default.disagree_action(reason, post_id, moderator_id, other_reason);
              });
            };
            modal.show(_reportReason.default, {
              model: {
                post_id,
                moderator_id,
                callback
              }
            });
          }
        });
      });
    }
  };
});